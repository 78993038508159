body {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root {
	height: 100%;
}

.react-calendar {
	border: unset !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.rowSelected {
	background-color: #a9fcdf !important;
}

.rowSelected:hover,
.ui.selectable.table tbody tr.rowSelected:hover {
	background-color: #d7fff1 !important;
}

span {
	overflow: hidden;
	text-overflow: ellipsis;
}

.fullWidthDatePicker,
.fullWidthDatePicker div.field,
.fullWidthDatePicker div.input,
.fullWidthDatePicker input {
	width: 100%;
	text-align: center !important;
}

.ui.statistic > .value.smallStatistic {
	font-size: 2.5rem !important;
}

.ui.statistic > .value.smallestStatistic {
	font-size: 2rem !important;
}

.rightTextField > div.input > input{
	text-align: right;
}

.only-print, only-print * {
	display: none !important;
}

@media print
{    
    .no-print, .no-print *
    {
        display: none !important;
	}
	
	.only-print, only-print *
	{
		display: inherit !important;
	}
}